import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { mapsActions } from "../../store";
import Chart from "./Chart";
import { Typography } from "@mui/material";
import useBinsStatusStyle from "./common/useBinsStatusStyle";
import { countRate } from "../util/converter";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "#f2f6fa",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "100%",
  },
}));

export default function BinsStatus() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.maps.showStatus);
  const setOpen = () => dispatch(mapsActions.toggleShowStatus());
  const binsStatusData = useSelector((state) => {
    if (state.maps.items.length) {
      const bins = state.maps.items;

      return {
        route: bins[0].route,
        empted: bins.filter((i) => i.empted).length,
        unempted: bins.filter((i) => !i.empted).length,
        total: bins.length,
        rate: countRate(
          bins.length,
          bins.filter((i) => i.empted).length
        ).toFixed(2),
      };
    }
    return undefined;
  });

  const classes = useBinsStatusStyle();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            background: "#f2f6fa",
            display: "flex",
            alignItems: "center",
          }}
          id="customized-dialog-title"
        >
          <span>Bins Status</span>
          <span
            style={{
              fontSize: "12px",
              borderRadius: "4px",
              marginLeft: "4px",
              background: " #108ed4",
              color: "white",
              padding: "1.5px 3px",
              alignSelf: "center",
            }}
          >
            {binsStatusData && binsStatusData?.route}
          </span>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {binsStatusData ? (
            <>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box>
                  <Box className={classes.binInfoBar}>
                    <Box className={classes.iconText}>
                      <DeleteIcon />

                      <Typography className={classes.typography}>
                        Total
                      </Typography>
                    </Box>
                    <Typography className={classes.typography}>
                      {binsStatusData.total}
                    </Typography>
                  </Box>
                  <Box className={classes.binInfoBar}>
                    <Box className={classes.iconText}>
                      <DeleteIcon color="positive" />
                      <Typography className={classes.typography}>
                        Empted
                      </Typography>
                    </Box>
                    <Typography className={classes.typography}>
                      {binsStatusData.empted}
                    </Typography>
                  </Box>
                  <Box className={classes.binInfoBar}>
                    <Box className={classes.iconText}>
                      <DeleteIcon color="negative" />
                      <Typography className={classes.typography}>
                        Unempted
                      </Typography>
                    </Box>
                    <Typography className={classes.typography}>
                      {binsStatusData.unempted}
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{ margin: "1rem 0 0.3rem" }}
                >
                  Empted bins rate
                </Typography>
                <Chart
                  style={{ marginTop: "1rem" }}
                  rate={binsStatusData.rate}
                />
              </Box>
            </>
          ) : (
            <div>Select a route !</div>
          )}
        </DialogContent>
        {/* <DialogActions sx={{ background: "#f2f6fa" }}>
          <Button
            variant="contained"
            size="meduim"
            sx={{
              margin: "0 0.5rem",
              background: "#0288d1f0",
              "&:hover": {
                background: "#0288d1",
              },
            }}
          >
            Update
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
